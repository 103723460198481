<template>
  <v-container class="mt-3 mb-16">
    <div class="banner-with-text">
      <label for="">นโยบายการเปลี่ยนคืนสินค้า</label>
      <img
        src="@/assets/images/banner/return-banner.png"
        class="hidden-sm-and-down"
      >
    </div>
    <div class="info-paragraph">
      <label
        class="info-body"
      >รับเปลี่ยนคืนสินค้า 100% ทั้ง กรณีสินค้าเสียหายจากการขนส่ง และกรณีสินค้าใกล้หมดอายุ โดยมีเงื่อนไขดังนี้</label>
      <label
        class="info-body"
      >1. กรณีสินค้าเสียหายจากการขนส่งหรือจากสาเหตุอื่น กรุณาแจ้งทางร้านให้รับทราบภายใน 3 วัน หลังจากรับสินค้า หากเกินกว่า 3 วัน ทางร้านขอสงวนสิทธิ์ไม่รับเปลี่ยนคืน</label>
      <label
        class="info-body"
      >2. กรณีสินค้าใกล้หมดอายุ กรุณาแจ้งทางร้านให้รับทราบภายใน 7 วัน หลังจากรับสินค้า หากเกินกว่า 7 วัน ทางร้านขอสงวนสิทธิ์ไม่รับเปลี่ยนคืน</label>
      <label
        class="info-body"
      >3. กรณีอื่นๆนอกเหนือจาก ข้อ 1 และ ข้อ 2 กรุณาแจ้งทางร้านให้รับทราบภายใน 10 วัน หลังจากรับสินค้า หากเกินกว่า 10 วัน ทางร้านขอสงวนสิทธิ์ไม่รับเปลี่ยนคืน</label>
      <label
        class="info-body"
      >4. ลูกค้าเป็นผู้รับผิดชอบค่าส่งสินค้ากลับ</label>
      <label
        class="info-body"
      >5. หลังจากได้รับสินค้าเปลี่ยนคืนจากลูกค้า ทางร้านจะดำเนินการส่งสินค้าใหม่ไปให้ทันที หากกรณีสินค้าหมด ทางร้านจะโอนเงินคืนเต็มจำนวน เพื่อป้องกันการแอบอ้าง และประโยชน์ของลูกค้าเอง ทางร้านจะโอนเงินคืนเข้าบัญชีในชื่อของผู้สมัครสมาชิกเท่านั้น</label>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'ReturnPolicy',
};
</script>
<style scoped>
.banner-with-text {
  height: 120px;
  background-color: #f6efff;
  width: 100%;
  display: flex;
}

.banner-with-text > * {
  display: inline-flex;
}

.banner-with-text > label {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.banner-with-text > img {
  margin-left: auto;
}

.info-paragraph {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
}

.info-paragraph > .info-header {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  color: #751fde;
  margin: 12px 0px !important;
}

.info-paragraph > .info-body {
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 600;
}
</style>